import React from "react";
import "./TagList.css";
import {Link} from "gatsby"

const TagList = ({locale, items}) => {
    return (
        (
            <p className="TagList">
                {items.map((node)=>{return (<Link to={"/" + locale + "/tag/" + node.slug} key={node.name}>{node.name}</Link>)})}
            </p>
        )
    );
};

export default TagList;