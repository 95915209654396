import React from "react";
import {Trans} from "@lingui/macro";
import IfNotEmpty from "./IfNotEmpty";
import "./VideoDetail.css"
import likeIcon from "./../../static/images/thumb_up-24px.svg"
import dislikeIcon from "./../../static/images/thumb_down-24px.svg"
import facebookIcon from "./../../static/images/facebook.svg"
import twitterIcon from "./../../static/images/twitter.svg"
import playIcon from "./../../static/images/play_arrow-24px.svg"
import userIcon from "./../../static/images/account_circle-24px.svg"
import TagList from "./TagList";
import {Container, Row, Col} from "reactstrap";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {graphql, useStaticQuery} from "gatsby";

const VideoDetail = ({locale, item, location}) => {

    const query = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              twitterHandle,
              videoRest {
                endpoints {
                  likes
                  dislikes
                }
              }
            }
          }
        }
    `);

    var d = item ? new Date(item.publication_date) : null;
    const videoId = item ? item.url.split("/").pop() : null;

    const duration = Math.floor(item.json.duration / 60) + ':' + (item.json.duration % 60);

    //todo zmenit ak video.tags bude zoznam objektov typu tag aby pole obsahovalo tagName
    const twitterTags = item.tags.map(tagNode => tagNode);

    var likeUrl = query.site.siteMetadata.videoRest.endpoints.likes
    likeUrl = likeUrl.replace('#videoId#', item.id);

    var dislikeUrl = query.site.siteMetadata.videoRest.endpoints.dislikes
    dislikeUrl = dislikeUrl.replace('#videoId#', item.id);

    const likeClickHandler = () => {
        if (sessionStorage.getItem('liked') === item.id) {
            return;
        }
        fetch(likeUrl)
            .then(response => response.json())
            .then(result => {
                sessionStorage.setItem('liked', result.id);
                document.getElementById('like').innerText = result.like;
                document.getElementById('dislike').innerText = result.dislike;
            }).catch(error => {
                console.log(error);
            });
    };

    const dislikeClickHandler = () => {
        if (sessionStorage.getItem('liked') === item.id) {
            return;
        }
        fetch(dislikeUrl)
            .then(response => response.json())
            .then(result => {
                sessionStorage.setItem('liked', result.id);
                document.getElementById('like').innerText = result.like;
                document.getElementById('dislike').innerText = result.dislike;
            }).catch(error => {
                console.log(error);
            });
    };

    return (!item ? '' : (
        <div className="VideoDetail">
            <div className="SideBarBox CinemaHolder">
                <Container>
                    <div className="Cinema">
                        <div className="VideoHolder">
                            <iframe title={"iframe" + item.id} src={"https://player.vimeo.com/video/" + videoId}
                                    frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>
                    </div>
                    <Row>
                        <Col md="9">
                            <h2>{item.title}</h2>
                            <p className="Credits">
                                <span className="action"><img className="icon" src={userIcon}
                                                              alt="Autor"/> {item.author}</span>
                                <span
                                    className="action"><strong><Trans>Dátum</Trans></strong>: {d.toLocaleDateString('sk')}</span>
                                <span
                                    className="action"><strong><Trans>Dĺžka</Trans></strong>: {duration}</span>
                            </p>
                            <p className="description">
                                {item.description}
                            </p>
                            <IfNotEmpty items={item.tags}>
                                <TagList locale={locale} items={item.tagObjs}/>
                            </IfNotEmpty>
                        </Col>
                        <Col md="3">
                            <div className="actions">
                                <div className="actionGroup">
                                    <span className="action"><img className="icon" src={playIcon}
                                                                  alt="Počet videní"/> {item.json.stats.plays}</span>
                                    <span className="action"><img className="icon" src={likeIcon} alt="like" onClick={likeClickHandler} /> <span id="like">{item.like}</span></span>
                                    <span className="action"><img className="icon" src={dislikeIcon} alt="dislike" onClick={dislikeClickHandler} /> <span id="dislike">{item.dislike}</span></span>
                                </div>
                                <div className="actionGroup">
                                    <span className="action">
                                        <FacebookShareButton url={location.href}>
                                            <img className="icon" src={facebookIcon} alt="Facebook"/> <Trans>Facebook</Trans>
                                        </FacebookShareButton>
                                    </span>
                                    <span className="action">
                                        <TwitterShareButton url={location.href} title={item.title} via={query.site.siteMetadata.twitterHandle} hashtags={twitterTags}>
                                            <img className="icon" src={twitterIcon} alt="Twitter"/> <Trans>Twitter</Trans>
                                        </TwitterShareButton>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    ));
};

export default VideoDetail;
