import React, {useEffect, useState} from "react";
import {withI18n} from "@lingui/react";
import {graphql, useStaticQuery} from "gatsby";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import {getNodeFromLocationPath} from "utils/utils.js"
import VideoDetail from "../../components/VideoDetail";
import SEO from "../../components/seo";

const VideoDetailPage = ({location, data, pageContext}) => {

    const query = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              videoRest {
                endpoints {
                  video
                }
              }
            }
          }
        }
    `);

    const locale = getLocaleFromPath(location.pathname);
    const id = getNodeFromLocationPath(location, 3);

    var videoUrl = query.site.siteMetadata.videoRest.endpoints.video;
    videoUrl = videoUrl.replace('#videoId#', id);

    const [video, setVideo] = useState(null);
    useEffect(() => {
        fetch(videoUrl)
            .then(response => response.json())
            .then(result => {
                setVideo(result);
            })
            .catch(error => {
                console.log(error);
                setVideo(null);
            });
    }, [videoUrl]);

    return (!video ? '' : (
        <Layout location={location}>
            <div className="MainContent">
                <VideoDetail locale={locale} item={video} location={location}/>
                <SEO
                    title={video.title}
                    description={video.description}
                    author={video.author}
                    url={location.href}
                    image={{
                        src: video.json.pictures.sizes[0].link,
                        width: video.json.pictures.sizes[0].width,
                        height: video.json.pictures.sizes[0].height
                    }}
                    lang="sk" />
            </div>
        </Layout>
    ));
};

export default withI18n()(VideoDetailPage);
